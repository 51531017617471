<template>

    <div class="d-lg-none">

        <div v-if="loading">
            <div class="card bg-info mt-4">
                <loading-animation/>
                Please wait while loading data from the server.
            </div>
        </div>

        <div v-else-if="error">
            <div class="card bg-danger mt-4">
                <error-icon/>
                Failed to load data from the server.
            </div>
        </div>

        <div v-else>

            <div v-for="(i, k) in details.data" v-bind:key="k" class="mt-3">
                <div class="b-1 bs-3">
                    <div class="row b-1 fs--1 m-0">
                        <span class="b-1 col-6 w-100 col-12">
                            <div class="p-1">
                                <span class="font-inter-medium text-5">Auction ID</span> &nbsp;:
                                <span class="ml-2">{{ i.id }}</span>
                            </div>
                        </span>
                        <span class="b-1 col-6 w-100 col-12">
                            <div class="p-1">
                                <span class="font-inter-medium text-5">Auction Name&nbsp;</span>:
                                <span class="ml-2">{{ i.title }}</span>
                            </div>
                        </span>
                        <span class="b-1 col-6 w-100 col-12">
                            <div class="p-1">
                                <span class="font-inter-medium text-5">Bank/FI&nbsp;</span>:
                                <span v-if="i.event_bank.bank_name" class="ml-2">{{ i.event_bank.bank_name }}</span>
                                <span v-else class="ml-2">N/A</span>
                            </div>
                        </span>
                        <span class="b-1 col-6 w-100 col-12">
                            <div class="p-1">
                                <span class="font-inter-medium text-5">State&nbsp;</span>:
                                <span v-if="i.state" class="ml-2">{{ i.state }}</span>
                                <span v-else class="ml-2">N/A</span>
                            </div>
                        </span>
                        <span class="b-1 col-6 w-100 col-12">
                            <div class="p-1">
                                <span class="font-inter-medium text-5">District&nbsp;</span>:
                                <span v-if="i.district" class="ml-2">{{ i.district }}</span>
                                <span v-else class="ml-2">N/A</span>
                            </div>
                        </span>
                        <span class="b-1 col-6 w-100 col-12">
                            <div class="p-1">
                                <span class="font-inter-medium text-5">  Platform&nbsp; </span>: <span
                                v-if="!i.is_third_party" class="ml-2">Bank e Auctions India.com</span>
                                <span v-else class="ml-2">Other</span>
                            </div>
                        </span>
                        <span class="b-1 col-12 w-100 col-12">
                            <div class="p-1">
                                <span class="font-inter-medium text-5"> Auction Status </span> : <span class="ml-2">Drafted</span>
                            </div>
                        </span>
                        <span class="b-1 col-6 ">
                            <div class="pt-2 pl-2 font-inter-medium ">
                                <span class="font-inter-medium text-5"> Actions </span>
                            </div>
                        </span>
                        <span class="b-1 col-6">
                            <div class="p-1 fl-x fl-j-c">
                                <div class="btn-group">
                                    <btn design="" icon="fa fa-eye" shadow="0" class="border-gray-hover p-2 text-5"
                                         @click="setView(i)"/>
                                    <btn design="" icon="fa fa-pencil" shadow="0" class="border-gray-hover p-2 text-5"
                                         @click="setEdit(i)"
                                         v-if="currentUser.is_superuser || currentUser.is_bank_admin ||
                                        currentUser.permission.can_edit_auction"/>
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
            </div>
            <div v-if="details.data.length !== 0" class="mt-3 fl-te-c-res">
                <div class="mb-2">
                    Showing {{ details.from }} to {{ details.to }} of {{ details.total }} entries
                </div>
                <div>
                    <btn class="m-1" size="sm" @click="prevClicked" v-if="prev" type="button"
                         icon="fa fa-chevron-left"/>
                    <btn class="m-1" size="sm" disabled v-else type="button" icon="fa fa-chevron-left"/>
                    <btn class="m-1" size="sm" type="button">{{ details.current_page }}</btn>
                    <btn class="m-1" size="sm" @click="nextClicked" v-if="next" type="button"
                         icon="fa fa-chevron-right"/>
                    <btn class="m-1" size="sm" disabled v-else type="button" icon="fa fa-chevron-right"/>
                </div>
            </div>

            <div v-if="details.data.length === 0" class="card">
                <div class="mb-2">
                    <div>
                        <div class="fl-x fl-j-c fl-a-c">
                            No Data
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import axios from 'secure-axios';
import urls from '../../../data/urls';
import _ from 'lodash';

export default {
    name : 'DraftedAuctionsCardMobile',

    props : {
        ext_search : {
            type : String
        },
        is_third_party : {
            type    : Boolean,
            default : false
        }
    },

    watch : {

        is_third_party () {
            this.timeOutLoadData();
        },

        ext_search () {
            this.timeOutLoadData();
        }
    },

    data () {
        return {
            prev     : '',
            next     : '',
            page     : '1',
            per_page : '10',
            loading  : true,
            error    : false,
            details  : null
        };
    },

    computed : {
        ...mapGetters(['currentUser'])
    },

    mounted () {
        this.loadData();
    },

    methods : {
        timeOutLoadData : _.debounce(function () {
            this.loadData();
        }, 2000),
        loadData () {
            const that = this;

            that.loading = true;
            that.error = false;

            axios.get(urls.auction.auction.draftedAuctionList, {
                params : {
                    page           : that.page,
                    per_page       : that.per_page,
                    is_third_party : that.is_third_party,
                    ext_search     : that.ext_search
                }
            }).then(function (response) {
                const json = response.data;
                that.details = { ...json };
                that.loading = false;
                that.error = false;
                that.setPagination();
            }).catch(function (exception) {
                console.log('exception : ', exception);
                that.loading = false;
                that.error = true;
            });
        },
        setPagination () {
            this.prev = parseInt(this.details.current_page) !== this.details.from;
            this.next = parseInt(this.details.current_page) !== this.details.last_page;
        },
        prevClicked () {
            this.page = parseInt(this.page) - 1;
            this.loadData();
        },
        nextClicked () {
            this.page = parseInt(this.page) + 1;
            this.loadData();
        },

        setView (item) {
            this.$emit('view', item);
        },
        setEdit (item) {
            this.$router.push('/edit/' + item.id + '/auction/');
        }

    }

};
</script>

<style scoped>

</style>
