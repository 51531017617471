<template>
    <div class="pt-5 pt-lg-0">
        <inner-page-card :scrollbar="true">

            <template #header-area>
                <div class="fl-x">
                    <span class="mr-2">
                        <img src="../../assets/web/icons/header-icons/auction-list-icon.png" class="icon-sm" alt="">
                    </span>
                    <p class="font-inter-medium text-center d-inline fs-lg-2 m-0">Drafted Auction List</p>
                </div>
            </template>

            <template #default>

                <auctions-list-filter :is_draft_auction="true" :model="filter" @setValue="setValue"/>

                <custom-vue-table class="d-sm-none" :fields="fields" :url="listUrl" :per-page="10" ref="table"
                                  :extra-params="{
                            ext_search: filter.ext_search,
                            is_third_party: filter.is_third_party
                           }" :show-search-box="false">
                    <template #status>
                        <span>Drafted</span>
                    </template>
                    <template #is_third_party="{rowData}">
                        <span v-if="!rowData.is_third_party">Bank e Auctions India.com</span>
                        <span v-else>Other</span>
                    </template>

                    <template #bank_name="{rowData}">
                        <span v-if="rowData.event_bank.bank_name">{{ rowData.event_bank.bank_name }}</span>
                        <span v-else>N/A</span>
                    </template>

                    <template #state="{rowData}">
                        <span v-if="rowData.state">{{ rowData.state }}</span>
                        <span v-else>N/A</span>
                    </template>

                    <template #district="{rowData}">
                        <span v-if="rowData.district">{{ rowData.district }}</span>
                        <span v-else>N/A</span>
                    </template>

                    <template #actions="{rowData}">
                        <div class="btn-group-tight">
                            <btn design="" icon="fa fa-eye" class="border-gray-hover p-2 text-5"
                                 @click="setView(rowData)"/>
                            <btn design="" icon="fa fa-pencil" class="border-gray-hover p-2 text-5"
                                 @click="setEdit(rowData)"
                                 v-if="currentUser.is_superuser || currentUser.is_bank_admin ||
                                 currentUser.permission.can_edit_auction"/>
                        </div>
                    </template>

                </custom-vue-table>

                <drafted-auctions-card-mobile class="d-lg-none"
                                              :is_third_party="filter.is_third_party"
                                              :ext_search="filter.ext_search"
                                              @view="setView"></drafted-auctions-card-mobile>

            </template>

        </inner-page-card>
    </div>
</template>

<script>
import InnerPageCard from '../../components/ui/InnerPageCard';
import AuctionsListFilter from '../../components/auction/auctions-list/AuctionsListFilter';
import urls from '@/data/urls';
import { mapGetters } from 'vuex';
import DraftedAuctionsCardMobile from '../../components/auction/auctions-list/DraftedAuctionsCardMobile';

export default {
    name : 'DraftedAuctionsListPage',

    components : { DraftedAuctionsCardMobile, AuctionsListFilter, InnerPageCard },

    data () {
        return {
            deleteUrl    : urls.auction.auction.delete,
            deletingItem : null,
            editingItem  : null,
            status       : this.$route.params.status,

            listUrl : urls.auction.auction.draftedAuctionList,

            filter : {
                propertyType : '',
                state        : '',
                district     : '',
                status       : '',
                ext_search   : '',
                bank         : ''
            },

            fields : [
                {
                    name  : 'id',
                    title : 'Auction ID'
                },
                {
                    name  : 'title',
                    title : 'Auction Name'
                },
                {
                    name  : '__slot:bank_name',
                    title : 'Bank/FI'
                },
                {
                    name  : '__slot:state',
                    title : 'State'
                },
                {
                    name  : '__slot:district',
                    title : 'District'
                },
                {
                    name  : '__slot:is_third_party',
                    title : 'Platform'
                },
                {
                    name  : '__slot:status',
                    title : 'Auction Status'
                },
                {
                    name       : '__slot:actions',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right',
                    title      : 'Actions'
                }
            ]
        };
    },

    beforeMount () {
        this.setStatus();
    },

    computed : {
        filters () {
            return this.filter;
        },

        ...mapGetters(['currentUser'])
    },

    methods : {

        setStatus () {
            if (this.status) {
                this.filter.status = this.status;
            }
        },

        setView (rowData) {
            this.$router.push({ path : '/auction/' + rowData.id + '/details/' });
        },

        setEdit (item) {
            this.$router.push('/edit/' + item.id + '/auction/');
        },

        setValue (data) {
            this.filter[data.key] = data.value;
        }
    }

};
</script>

<style scoped lang="scss">
.pos-a-b {
    position: absolute;
    bottom: 0;
}
</style>
