<template>
    <s-form>
        <div class="row">
            <div class="pt-2 col-12">
                <p class="font-inter-semi-bold fs-1">Filters</p>
            </div>

            <div v-if="!is_draft_auction" class="col-lg-3 col-md order-lg-1  px-1">

                <validated-select class="ml-2  order-lg-2 c-input-select-b" v-model="filter.propertyType"
                                  placeholder="Category Type" :options="propertyTypeOptions"/>
            </div>

            <div class=" col-lg-3 col-md order-lg-2  px-1"
                 v-if="!is_draft_auction && (currentUser.is_superuser || currentUser.is_ventura_admin)">

                <validated-select class="ml-2  order-lg-2 c-input-select-b" v-model="filter.bank"
                                  placeholder="Bank/FI’s" :options="bankOptions"/>

            </div>

            <!--            <div v-if="!is_draft_auction" class=" col-lg-3 col-md order-lg-3  px-1">-->
            <!--                <validated-select class="ml-2  order-lg-3  c-input-select-b" v-model="filter.state"-->
            <!--                                  placeholder="State" :options="stateOptions" @input="setDistrict(filter.state)"/>-->
            <!--            </div>-->

            <div v-if="!is_draft_auction" class=" col-lg-3 col-md order-lg-3  px-1">
                <validated-select class="ml-2  order-lg-3  c-input-select-b" v-model="filter.state"
                                  placeholder="State" :options="stateOptions" @input="setDistrict(filter.state)"/>
            </div>

            <div v-if="!is_draft_auction" class=" col-lg-3 col-md order-lg-3  px-1">
                <validated-select class="ml-2 order-lg-4 c-input-select-b" v-model="filter.district"
                                  placeholder="District" :options="districtOptions"/>
            </div>

            <div v-if="!is_draft_auction" class="col-lg-3 col-md order-lg-5  px-1">
                <validated-select class="ml-2 order-lg-5 c-input-select-b"
                                  placeholder="Status" v-model="filter.status"
                                  :options="statusOptions"/>
            </div>

            <div v-if="currentUser.is_superuser || currentUser.is_ventura_admin"
                 class="col-lg-3 col-md order-lg-6  px-1">
                <validated-select class="ml-2 order-lg-2 c-input-select-b" v-model="filter.is_third_party"
                                  placeholder="Platform" :options="booleanOptions"/>
            </div>
            <div class=" col-lg-3 col-md order-lg-7  px-1">
                <validated-input class="ml-2 order-1 order-lg-6" placeholder="Search(Auction Id/ Name)"
                                 v-model="filter.ext_search">
                    <template #append>
                        <i class="fa fa-search"></i>
                    </template>
                </validated-input>
            </div>
            <div class="order-lg-7">
                &nbsp;
            </div>
            <div v-if="!is_draft_auction" class="order-2 order-lg-7">
                <div class="fl-x fl-j-c mb-3">
                    <router-link to="/add-auction/"
                                 v-if="currentUser.is_superuser || currentUser.is_bank_admin
                                 || currentUser.permission.can_add_auction">
                        <btn class="ml-3"
                             text="Add Auction" type="button" size="" color="secondary"></btn>
                    </router-link>
                </div>
            </div>
        </div>

    </s-form>
</template>

<script>
import urls from '../../../data/urls';
import axios from 'secure-axios';
import { mapGetters } from 'vuex';

export default {
    name : 'AuctionsListFilter',

    props : {
        is_draft_auction : {
            type    : Boolean,
            default : true
        },
        model : {
            type : Object,
            default () {
                return {
                    propertyType   : '',
                    is_third_party : '',
                    state          : '',
                    district       : '',
                    status         : '',
                    ext_search     : '',
                    bank           : ''
                };
            }
        }
    },

    computed : {
        filter () {
            return this.model;
        },

        ...mapGetters(['currentUser'])
    },

    data () {
        return {
            status : '',

            statusOptions : [
                { label : 'All', value : 'All' },
                { label : 'Published', value : 'Created' },
                { label : 'Cancelled', value : 'Cancelled' },
                { label : 'Suspended', value : 'Suspended' },
                { label : 'Started', value : 'Started' },
                { label : 'Ended', value : 'Ended' },
                { label : 'Completed', value : 'Completed' }
            ],

            booleanOptions : [
                { label : 'Bank e Auctions India.com', value : false },
                { label : 'Others', value : true }
            ],

            propertyTypeOptions : [],
            stateOptions        : [],
            districtOptions     : [],
            bankOptions         : [],
            AllDistrictOptions  : []

        };
    },

    beforeMount () {
        this.loadBank();
        this.loadStates();
        this.loadDistricts();
        this.loadPropertyTypes();
    },

    methods : {

        async loadBank () {
            const response = await axios.get(urls.auction.bank.bankOptions);
            this.bankOptions = response.data.data;
            this.bankOptions.unshift({ label : 'All', value : 'All' });
        },

        async loadStates () {
            const response = await axios.get(urls.auction.auctionList.stateVueSelect + '?type=Text');
            this.stateOptions = response.data.data;
            this.stateOptions.unshift({ label : 'All', value : 'All' });
        },

        async loadDistricts () {
            const response = await axios.get(urls.auction.auctionList.districtVueSelect + '?type=Text');
            this.districtOptions = response.data.data;
            this.AllDistrictOptions = response.data.data;
            this.districtOptions.unshift({ label : 'All', value : 'All' });
        },

        async loadPropertyTypes () {
            const response = await axios.get(urls.auction.auctionList.propertyVueSelect + '?type=Text');
            this.propertyTypeOptions = response.data.data;
            this.propertyTypeOptions.unshift({ label : 'All', value : 'All' });
        },

        setValue (key, value) {
            this.$emit('setValue', { key : key, value : value });
        },

        setDistrict (state) {
            if (state === 'All') {
                this.filter.district = '';
                this.districtOptions = [];
                this.districtOptions = [...this.AllDistrictOptions];
            } else {
                this.filter.district = '';
                const foundValues = this.AllDistrictOptions.filter(ele => ele.state === state);
                this.districtOptions = [];
                this.districtOptions = [...foundValues];
                if (this.districtOptions.length > 0) {
                    this.districtOptions.unshift({ label : 'All', value : 'All', state : '' });
                }
            }
        }
    }
};
</script>

<style scoped lang="scss">
//.filter-grid {
//    display: grid;
//    width: 100%;
//    grid-template-columns: 1fr 3fr repeat(3, 2fr) 3fr 3fr;
//}

.filter-grid {
    display: grid;
    width: 100%;
    //grid-template-columns: repeat(3, 1fr);
    grid-template-columns: 1fr 1fr;
    @media(min-width: 992px) {
        //grid-template-columns: 1fr 3fr repeat(3, 3fr) 3fr 2fr;
        grid-template-columns: repeat(5, 1fr);
    }
}
</style>
